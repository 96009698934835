.prompting_container.loading {
    overflow: hidden;
    pointer-events: none;
    opacity: 0.7;
}
.card {
    padding: 20px;
    background: #ffffff;
    border-radius: 20px;
    margin-bottom: 10px;
}

input,
textarea {
    width: 98%;
    font-family: sans-serif;
    padding: 10px;
    font-size: 1.1em;
}
legend {
    font-size: 1em;
    font-weight: bold;
    padding: 10px 0;
}

.prompts_block {
    display: flex;
    justify-content: space-between;
}

.prompt {
    width: 48%;
}

.prompt_send {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.test_report_block {
    margin-top: 20px;
}

.prompt_send button {
    width: 150px;
    height: 50px;
    padding: 10px;
    border-radius: 15px;
    cursor: pointer;
    background: #a9fae5;
    border: 0;
    transition: all 0.3s;
    font-weight: 600;
    line-height: 1.8;
    color: #5c4bce;
}

.prompt_send button:hover {
    transform: scale(1.05);
}

@media screen and (max-width: 1025px) {
    .prompts_block {
        display: block;
    }
    .prompt {
        width: 100%;
    }
}
