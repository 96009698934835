.modal_container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
}

.modal {
    display: grid;
    grid-column: 2;
    grid-row: 2;
    place-self: center center;
    position: relative;
    box-sizing: border-box;
    grid-template-columns: minmax(0, 100%);
    width: 32em;
    max-width: 100%;
    border: none;
    border-radius: 5px;
    background: #fff;
    color: hsl(0, 0%, 33%);
    font-family: inherit;
    font-size: 1rem;
    animation: modal-to-show 0.3s;
    padding: 30px;
}

.modal input {
    width: auto;
    height: 2.625em;
    padding: 0 0.75em;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin: 1em 2em 3px;
    box-sizing: border-box;
    transition:
        border-color 0.1s,
        box-shadow 0.1s;
    border: 1px solid hsl(0, 0%, 85%);
    border-radius: 0.1875em;
    background: rgba(0, 0, 0, 0);
    box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.06),
        0 0 0 3px rgba(0, 0, 0, 0);
    color: inherit;
    font-size: 1.125em;
}

.modal_actions {
    z-index: 1;
    box-sizing: border-box;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: auto;
    margin: 1.25em auto 0;
    padding: 0;
}

.modal_actions button {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #7066e0;
    color: #fff;
    font-size: 1em;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow 0.1s;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
    font-weight: 500;
    cursor: pointer;
}

.modal_actions button:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

@keyframes modal-to-show {
    0% {
        transform: scale(0.7);
    }
    45% {
        transform: scale(1.05);
    }
    80% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(1);
    }
}
