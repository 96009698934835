.term-modal-wrapper {
    position: sticky;
    top: 30px;
    z-index: 1000;
    transition: opacity 0.3s;
}

.term-modal-content {
    background-color: #4b44a5;
    transform-origin: center;
    box-shadow: 0px 4px 8px -2px rgba(41, 37, 91, 0.7);
    transition-property: height;
    border-radius: 25px;
    transition-duration: 300ms;
    transition-timing-function: ease-out;
    overflow-y: hidden;
    animation-name: fade-in-def-content;
    animation-duration: 215ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
    padding: 30px;
}

.term-content {
    opacity: 0;
    transform: translateY(20px);
    transition:
        opacity 0.3s ease-in-out,
        transform 0.3s ease-in-out;
}

.term-content.visible {
    opacity: 1;
    transform: translateY(0);
}

.term-title {
    font-size: 1.25em;
    font-weight: 600;
    color: #b3fbe8;
    line-height: 1.25;
    padding-bottom: 0.625em;
    margin-bottom: 0.625em;
    margin-top: 0;
    display: inline-block;
    border-bottom: 2px rgba(111, 247, 211, 0.2) solid;
}

.term-description {
    color: #edecf6;
    line-height: 1.5;
    font-weight: 500;
}

.term-image img {
    opacity: 0;
    transition: all 0.3s ease-in;
    width: 100%;
    transition:
        opacity 0.3s ease-in-out,
        transform 0.3s ease-in-out;
}

.term-image.visible img {
    opacity: 1;
}

.x-panel {
    display: flex;
    justify-content: flex-end;
}
.x-panel svg {
    width: 25px;
    cursor: pointer;
    transition: all 0.3s;
}
.x-panel svg:hover {
    transform: scale(1.4);
}

@media screen and (max-width: 1025px) {
    .term-modal-wrapper {
        opacity: 0;
        top: 0;
    }
    .term-modal-content {
        padding: 20px;
    }
}
.cls-1 {
    fill: none;
    stroke: #8a7bea;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
}

.cls-2 {
    opacity: 0;
}
