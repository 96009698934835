body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #ebebeb;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.container {
    padding: 30px;
}
@media screen and (max-width: 580px) {
    .container {
        padding: 5px;
    }
}
/*
.textarea > textarea {
  width: 100%;
  border-radius: 15px;
  font-size: 16px;
  padding: 8px;
}
.textarea button {
  height: 40px;
  background: #242b2e;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}
.text {
  font-size: 16px;
  font-weight: 500;
  margin-top: 40px;
}
.term {
  color: blue;
  cursor: pointer;
}
.term:hover {
  color: teal;
} */
