.report-and-term {
    position: relative;
    display: flex;
    width: 100%;
}
.report-and-term.loading {
    opacity: 0.5;
}

.term-modal-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    height: 100%;
}

.report {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 42px 40px 50px;
    width: 50%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
}

.term-modal {
    position: absolute;
    left: 0;
    right: 0;
    padding: 30px;
    transition: top 0.3s ease;
}

.report-section {
    margin-bottom: 20px;
}

.report-title {
    color: #006400;
    font-weight: bold;
    margin-bottom: 5px;
    position: relative;
    display: inline-block;
}

.title-highlight {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #a8e6cf;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-out;
}

.report-title:hover .title-highlight {
    transform: scaleX(1);
}

.report-content {
    margin-top: 0;
    text-align: justify;
}

.term {
    cursor: pointer;
    transition: background-color 0.3s ease;
    transition: 150ms ease-out;
    transition-property: box-shadow, color, background-color;
    background-color: transparent;
    color: #333333;
    font-weight: 600;
    box-shadow:
        inset 0 -0.15em #6ff7d3,
        inset 0 -1.25em rgba(221, 253, 245, 0.73);
}

.term:hover {
    box-shadow:
        inset 0 -1.25em rgba(111, 247, 211, 0.5),
        inset 0 -1.25em rgba(221, 253, 245, 0.73);
}

.term.dangerous {
    cursor: pointer;
    transition: background-color 0.3s ease;
    transition: 150ms ease-out;
    transition-property: box-shadow, color, background-color;
    background-color: transparent;
    color: #333333;
    font-weight: 600;
    box-shadow:
        inset 0 -0.15em #f76f6f,
        inset 0 -1.25em rgba(253, 221, 221, 0.73);
}

.term.dangerous:hover {
    box-shadow:
        inset 0 -1.25em rgba(254, 190, 190, 0.65),
        inset 0 -1.25em rgba(255, 233, 233, 0.66);
}

.text {
    line-height: 1.8;
    font-size: 1.25em;
}

.click-instruction.mobile {
    margin-top: 20px;
    pointer-events: none;
    background-color: #dcd6ff;
    border: 2px solid #d3ccff;
    border-radius: 10px;
    padding: 10px 15px;
    margin-bottom: 15px;
    width: auto;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: max-content;
    font-size: 1em;
    font-weight: 500;
}

.click-instruction.mobile svg {
    min-width: 50px;
    max-width: 50px;
}

@media screen and (max-width: 1220px) {
    table {
        font-size: 0.8em;
    }
}

@media screen and (max-width: 1025px) {
    .report {
        width: 100%;
    }

    .tutorial {
        display: none;
    }

    .term-modal-container {
        position: absolute;
        width: max-content;
        left: 0;
        top: 0;
        width: 95%;
        margin-left: 2.5%;
        height: max-content;
    }

    .click-instruction.mobile {
        display: flex;
    }
}

@media screen and (max-width: 720px) {
    .text {
        font-size: 1em;
        line-height: 2em;
    }
}

@media screen and (max-width: 580px) {
    table {
        font-size: 0.6em;
    }

    .report {
        padding: 20px;
    }
}

/* SVG tutorial */
.tutorial {
    position: sticky;
    top: 30%;
    z-index: 1000;
    transition: all 0.3s;
    pointer-events: none;
    display: flex;
    justify-content: center;
}

.tutorial-text.desktop {
    max-width: 300px;
}

.tutorial-text {
    font-size: 1.25em;
    text-align: center;
}

.tutorial svg {
    height: 190px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.t-d_cls-1 {
    stroke: #a9fae5;
}

.t-d_cls-1,
.t-d_cls-2,
.t-d_cls-3,
.t-d_cls-4,
.t-d_cls-5,
.t-d_cls-6,
.t-d_cls-7 {
    fill: none;
}

.t-d_cls-1,
.t-d_cls-2,
.t-d_cls-4,
.t-d_cls-5,
.t-d_cls-6 {
    stroke-miterlimit: 10;
}

.t-d_cls-1,
.t-d_cls-2,
.t-d_cls-4,
.t-d_cls-5,
.t-d_cls-6,
.t-d_cls-8,
.t-d_cls-9,
.t-d_cls-7 {
    stroke-linecap: round;
}

.t-d_cls-1,
.t-d_cls-2,
.t-d_cls-8,
.t-d_cls-9,
.t-d_cls-7 {
    stroke-width: 3px;
}

.t-d_cls-2,
.t-d_cls-8,
.t-d_cls-9,
.t-d_cls-7 {
    stroke: #000;
}

.t-d_cls-10 {
    clip-path: url(#t_desktop-screen-clip);
}

.t-d_cls-3,
.t-d_cls-11,
.t-d_cls-12,
.t-d_cls-13,
.t-d_cls-14,
.t-d_cls-15,
.t-d_cls-16,
.t-d_cls-17,
.t-d_cls-18,
.t-d_cls-19 {
    stroke-width: 0px;
}

.t-d_cls-11 {
    fill: #aa9efa;
}

.t-d_cls-11,
.t-d_cls-12,
.t-d_cls-13 {
    opacity: 0.8;
}

.t-d_cls-12 {
    fill: #6ff7d3;
}

.t-d_cls-13 {
    fill: #ff0;
}

.t-d_cls-4 {
    stroke: #ebebeb;
}

.t-d_cls-5 {
    stroke: #ece9ff;
}

.t-d_cls-5,
.t-d_cls-6 {
    stroke-width: 2px;
}

.t-d_cls-6 {
    stroke: #333;
}

.t-d_cls-20,
.t-d_cls-9 {
    opacity: 0.15;
}

.t-d_cls-8,
.t-d_cls-17 {
    fill: #fff;
}

.t-d_cls-8,
.t-d_cls-9,
.t-d_cls-7 {
    stroke-linejoin: round;
}

.t-d_cls-15 {
    fill: #5c4bce;
}

.t-d_cls-16 {
    fill: #ddd;
}

.t-d_cls-18 {
    fill: #ebebeb;
}

.t-d_cls-19 {
    fill: #0079a9;
}

.t-c_cls-1 {
    fill: #ff0;
    opacity: 0.8;
    stroke-width: 0px;
}

.t-c_cls-2 {
    fill: #fff;
}

.t-c_cls-2,
.t-c_cls-3,
.t-c_cls-4 {
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3px;
}

.t-c_cls-3 {
    opacity: 0.15;
}

.t-c_cls-4 {
    fill: none;
}

.t-m_cls-1,
.t-m_cls-2,
.t-m_cls-3,
.t-m_cls-4,
.t-m_cls-5,
.t-m_cls-6,
.t-m_cls-7,
.t-m_cls-8,
.t-m_cls-9 {
    fill: none;
}

.t-m_cls-2 {
    stroke: #a9fae5;
}

.t-m_cls-2,
.t-m_cls-3,
.t-m_cls-4,
.t-m_cls-5,
.t-m_cls-6 {
    stroke-miterlimit: 10;
}

.t-m_cls-2,
.t-m_cls-3,
.t-m_cls-4,
.t-m_cls-5,
.t-m_cls-6,
.t-m_cls-10,
.t-m_cls-11,
.t-m_cls-8 {
    stroke-linecap: round;
}

.t-m_cls-2,
.t-m_cls-3,
.t-m_cls-10,
.t-m_cls-11,
.t-m_cls-8 {
    stroke-width: 3px;
}

.t-m_cls-3,
.t-m_cls-10,
.t-m_cls-11,
.t-m_cls-8 {
    stroke: #000;
}

.t-m_cls-12 {
    fill: #ebebeb;
}

.t-m_cls-13,
.t-m_cls-10 {
    fill: #fff;
}

.t-m_cls-14 {
    fill: #0079a9;
}

.t-m_cls-15 {
    fill: #ddd;
}

.t-m_cls-16 {
    fill: #5c4bce;
}

.t-m_cls-4 {
    stroke: #ebebeb;
}

.t-m_cls-5 {
    stroke: #ece9ff;
}

.t-m_cls-5,
.t-m_cls-6 {
    stroke-width: 2px;
}

.t-m_cls-6 {
    stroke: #333;
}

.t-m_cls-10,
.t-m_cls-11,
.t-m_cls-8 {
    stroke-linejoin: round;
}

.t-m_cls-7 {
    clip-path: url(#t_mobile-screen-clip);
}

.t-m_cls-11,
.t-m_cls-9 {
    opacity: 0.15;
    fill: #000;
}

.t-m_cls-17 {
    fill: #6ff7d3;
}

.t-m_cls-17,
.t-m_cls-18,
.t-m_cls-19 {
    opacity: 0.8;
}

.t-m_cls-18 {
    fill: #aa9efa;
}

.t-m_cls-19 {
    fill: #ff0;
}

.report-action-button {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: max-content;
    height: 35px;
    border-radius: 15px;
    cursor: pointer;
    background: #a9fae5;
    border: 0;
    transition: all 0.3s;
    font-weight: 600;
    font-size: 13px;
    line-height: 1.8;
    color: #5c4bce;
}
.report-action-button svg {
    transition: all 0.3s;
    margin-right: 5px;
    width: 25px;
    height: 25px;
}
.report-action-button.mr {
    margin-right: 8px;
}

.report-action-button:hover {
    transform: scale(1.05);
}

.report-action-button.active {
    color: #5c4bce;
}

.report-edit-block {
    display: flex;
    justify-content: flex-end;
    margin-top: -30px;
    margin-right: -30px;
}

.report-textarea {
    width: 100%;
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    line-height: 1.8;
    font-size: 1.25em;
    overflow: auto;
    resize: none;
    outline: none;
}

.buttons {
    max-width: 225px;
    display: flex;
    justify-content: space-between;
}

.buttons-edit-upload {
    display: flex;
    justify-content: space-between;
    max-width: 350px;
}

@media screen and (max-width: 580px) {
    .report-edit-block {
        margin: 0;
    }

    .report-text-edit-button {
        margin: 0;
    }

    .buttons {
        width: 245px;
    }

    .report-textarea {
        line-height: 2em;
        font-size: 1em;
    }
}

.report-textarea:disabled {
    background: #ffffff;
    opacity: 0.6;
    pointer-events: none;
}

.textarea-block {
    position: relative;
}

.loader {
    position: absolute;
    top: 0;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(
        to left,
        rgba(251, 251, 251, 0.05),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.6),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.05)
    );
    background-image: -moz-linear-gradient(
        to left,
        rgba(251, 251, 251, 0.05),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.6),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.05)
    );
    background-image: -webkit-linear-gradient(
        to left,
        rgba(251, 251, 251, 0.05),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.6),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.05)
    );
    animation: loading 1s infinite;
    z-index: 45;
}
.all-window-loader {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
        to left,
        rgba(251, 251, 251, 0.05),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.6),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.05)
    );
    background-image: -moz-linear-gradient(
        to left,
        rgba(251, 251, 251, 0.05),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.6),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.05)
    );
    background-image: -webkit-linear-gradient(
        to left,
        rgba(251, 251, 251, 0.05),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.6),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.05)
    );
    animation: loading 1s infinite;
    z-index: 45;
}

@keyframes loading {
    0% {
        left: -45%;
    }
    100% {
        left: 62%;
    }
}

.prompt_testing_results button {
    width: 100%;
    font-size: 1.2em;
    padding: 0 10px;
    height: 35px;
    border-radius: 5px;
    margin-right: -30px;
    cursor: pointer;
    background: #a9fae5;
    border: 0;
    transition: all 0.3s;
    font-weight: 600;
    line-height: 1.8;
    color: #5c4bce;
}

.stage_results {
    opacity: 0;
    transition: all 0.5s;
    height: 0;
}

.stage_results.show {
    opacity: 1;
    height: 100%;
}

.dragging {
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #5c4bce;
    border-radius: 15px;
}

.dragging div {
    text-align: center;
}

.dragging h3 {
    font-size: 2em;
}

.dragging svg {
    animation: drop-here 1s ease-in-out infinite;
}

@keyframes drop-here {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

table {
    border-collapse: collapse;
    width: 100%; /* Таблица заполняет доступное пространство */
}

table th,
table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
    font-size: 16px; /* Читаемый размер текста */
}

table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

table td {
    word-wrap: break-word; /* Перенос длинного текста */
}

.table-wrapper {
    overflow-x: auto; /* Горизонтальный скролл для узких экранов */
    -webkit-overflow-scrolling: touch; /* Поддержка плавного скролла */
    margin-bottom: 10px;
}
